angular
  .module('workflow')

  .controller('TaskModalInstanceCtrl', [
    '$scope',
    '$modalInstance',
    '$filter',
    '$translate',
    '$timeout',
    'task',
    'company',
    'delivered',
    'Dialog',
    'LoggedUser',
    'ValueLang',
    'XlsxService',
    'CostsSummarySrv',
    'hideDownload',
    'UserLang',
    'GetUTypeToken',
    'GetToken',
    'Upload',
    'Confirm',
    function(
      $scope,
      $modalInstance,
      $filter,
      $translate,
      $timeout,
      task,
      company,
      delivered,
      Dialog,
      LoggedUser,
      ValueLang,
      XlsxService,
      CostsSummarySrv,
      hideDownload,
      UserLang,
      GetUTypeToken,
      GetToken,
      Upload,
      Confirm
    ) {
      $scope.token = GetToken();
      $scope.fileUrl = '@@fileUrl';
      $scope.xutype = GetUTypeToken();
      $scope.bucketName = '@@bucket_name';
      $scope.loggedUser = LoggedUser.getData();

      $scope.hideDownload = hideDownload;
      $scope.userLang = UserLang.get();

      const unwatchCompany = $scope.$watch('company', company => {
        if (!company) return;
        CostsSummarySrv.setCompany(company);
        unwatchCompany();
      });

      $scope.isContributor = LoggedUser.isContributor();
      $scope.isPM = LoggedUser.isPM();
      $scope.task = task ? angular.copy(task) : {};

      $scope.onUploadDone = () => {
        if (!$scope.isContributor) return;

        $timeout(() => {
          new Confirm()
            .show(
              $filter('translate')('contributors.ALL_FILES_UPLOADED_CONFIRM')
            )
            .then(() => {
              $timeout(() => {
                $modalInstance.close({
                  shouldSetDone: true,
                  task: $scope.task
                });
              }, 250);
            })
            .catch(() => undefined);
        });
      };

      if ($scope.isContributor) {
        CostsSummarySrv.setSource(task.source);
        CostsSummarySrv.setTarget(task.target);
        $scope.isNew = task.isNew || $scope.isNew;
      } else {
        const unwatchTask = $scope.$watch('task', task => {
          if (!task) return;
          $scope.isNew = task.isNew || $scope.isNew;
          if (Object.keys(task).length > 1) unwatchTask();
        });
      }

      const currentLang = $translate.proposedLanguage() || $translate.use();
      $scope.delivered = delivered;
      const dialog = new Dialog();

      const isTargetSet = () =>
        $scope.project &&
        $scope.project.services &&
        $scope.project.services[0] &&
        $scope.project.services[0].target &&
        $scope.project.services[0].target.length;

      if (isTargetSet()) {
        const { target } = $scope.project.services[0];

        target.forEach((t, index, targetsArray) => {
          $scope.targetString += (t.name && t.name[userLang]) || t.value;

          if (index < targetsArray.length - 1) {
            $scope.targetString += ' - ';
          }
        });
      }

      $scope.getName = unit => {
        const { value } =
          (unit && unit.name && ValueLang(unit, 'name', currentLang)) || {};
        return value;
      };

      const companyHasTrados = () =>
        company && company.settings && company.settings.hasTrados;

      $scope.taskIsOfTranslateType = () =>
        $scope.task.translate ||
        ($scope.selectedTask && $scope.selectedTask.translate);

      const selectedUnitHasRanges = () => $scope.unit && $scope.unit.ranges;

      const taskUnitHasRanges = () =>
        $scope.task.unit && $scope.task.unit.ranges;

      const unitHasRanges = () =>
        selectedUnitHasRanges() || taskUnitHasRanges();

      $scope.hasForeignCurrency = () =>
        !!(
          $scope.workflow &&
          $scope.workflow.currency &&
          $scope.workflow.currency !== 'EUR' &&
          $scope.workflow.exchangeRate
        );

      $scope.isTradosUnit = () =>
        /*companyHasTrados() ||*/ $scope.taskIsOfTranslateType() &&
        unitHasRanges();

      $scope.isTradosRequired = () =>
        $scope.isTradosUnit() && !$scope.hasForeignCurrency();

      const contributorHasUnitAndQuantitySet = (contributor = {}) => {
        const { quantity, unitPrice } = contributor;
        return typeof unitPrice === 'number' && typeof quantity === 'number';
      };

      $scope.contributorUnitHasRanges = (contributor = {}) =>
        contributor.unit &&
        (contributor.unit.ranges ||
          contributor.unit.code == '@@trados_uom_code');

      $scope.isTradosRequiredForContributor = contributor =>
        $scope.taskIsOfTranslateType() &&
        $scope.contributorUnitHasRanges(contributor) &&
        !contributorHasUnitAndQuantitySet(contributor);

      const manuallyAddedTask = () =>
        $scope.selectedService && $scope.selectedTask;

      const assignUnitToTask = () => {
        $scope.task.unit = Object.assign($scope.unit, {
          value:
            ValueLang($scope.unit, 'name', currentLang) &&
            ValueLang($scope.unit, 'name', currentLang).value,
          lc:
            ValueLang($scope.unit, 'name', currentLang) &&
            ValueLang($scope.unit, 'name', currentLang).lc
        });
      };

      const assignManualSelectionToTask = () => {
        Object.assign($scope.task, {
          _id: $scope.selectedTask._id,
          service_id: $scope.selectedService.service._id,
          service_name:
            $scope.selectedService.service.name ||
            $scope.selectedService.service.value,
          code: $scope.selectedTask.code,
          name: $scope.selectedTask.name || $scope.selectedTask.value,
          abbreviation: $scope.selectedTask.abbreviation,
          translate: $scope.selectedTask.translate,
          units: $scope.selectedTask.units,
          lang_required: $scope.selectedTask.lang_required
        });
      };

      const assignTradosSettingsToTask = () => {
        Object.assign($scope.task, { trados: $scope.trados });
      };

      if ($scope.isTradosRequired()) {
        $scope.trados = task.trados || angular.copy(company.trados_ranges);
      }

      $scope.ok = function() {
        if (!$scope.isNew) {
          if ($scope.taskIsOfTranslateType() && taskUnitHasRanges()) {
            assignTradosSettingsToTask();
          }
          return $modalInstance.close($scope.task);
        }

        if (!$scope.unit) {
          return dialog.error($filter('translate')('task.ERR_SERV_TASK_UNIT'));
        }

        $scope.task.isNew = false;

        assignUnitToTask();

        if (manuallyAddedTask()) {
          assignManualSelectionToTask();
        }

        if ($scope.isTradosRequired()) {
          assignTradosSettingsToTask();
          if (!selectedUnitHasRanges()) {
            return dialog.error(
              $filter('translate')('company.REQUIRES_TRADOS_ERR')
            );
          }
        }

        $modalInstance.close($scope.task);
      };

      $scope.cancel = params => {
        params
          ? $modalInstance.close(params)
          : $modalInstance.dismiss('cancel');
      };

      let inputTradosElement;
      let inputContributorTradosElement;

      $scope.inputTrados = () => {
        $timeout(() => {
          inputTradosElement.click();
        });
      };

      $scope.inputContributorTrados = contributor => {
        $timeout(() => {
          inputContributorTradosElement.click();
        });
      };

      const handleTradosFile = changeEvent => {
        $timeout(() => {
          const target = changeEvent && changeEvent.target;
          const file = target && target.files && target.files[0];
          changeEvent.target.value = '';

          XlsxService.readFile(file)
            .then(parsedFile => {
              XlsxService.getRanges(parsedFile).then(ranges => {
                ranges.forEach((range, index) => {
                  const taskRange = $scope.trados && $scope.trados[index];
                  taskRange.quantity = range.quantity;
                });
                dialog.success(
                  $filter('translate')('workflow.LOAD_TRADOS_LOG_SUCC')
                );
                $scope.ok();
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              dialog.error(
                $filter('translate')('workflow.LOAD_TRADOS_LOG_ERR')
              );
            });
        }, 100);
      };

      $scope.handleContributorTradosFile = ({
        target: eventTarget = {}
      } = {}) => {
        const { project } = $scope;
        let { task } = $scope;
        const { code } = task || {};
        task = code;
        const { services } = project || {};
        const [service] = services || [];
        let { source, target } = service || {};
        source = source || {};
        [target] = target || [];
        target = target || {};
        source = source.code;
        target = target.code;

        $timeout(() => {
          const { files } = eventTarget;
          const [file] = files || [];
          eventTarget.value = '';

          Upload.upload({
            url: '@@workflowApiUrl' + '/contributor-trados',
            data: {
              contributor: $scope.currentTradosContributor._id,
              file,
              source,
              target,
              task
            }
          })
            .then(({ data }) => {
              if (Array.isArray(data)) {
                $scope.currentTradosContributor.trados = data;
                $scope.setContributorCost($scope.currentTradosContributor);
              }
              delete $scope.currentTradosContributor;
            })
            .catch(error => dialog.error(error));
        });
      };

      $timeout(() => {
        inputTradosElement = document.getElementById('inputTrados');
        inputTradosElement &&
          inputTradosElement.addEventListener(
            'change',
            handleTradosFile,
            false
          );
        inputContributorTradosElement = document.getElementById(
          'inputContributorTrados'
        );
        inputContributorTradosElement &&
          inputContributorTradosElement.addEventListener(
            'change',
            $scope.handleContributorTradosFile,
            false
          );
      }, 250);

      $scope.setUnitPrice = unit => {
        Object.assign($scope.task, $scope.selectedTask || {});
        $scope.task.unit = unit || {};
        const customCompanyList = CostsSummarySrv.findCustomCompanyList(
          $scope.task
        );
        const customCompanyListByUnit = CostsSummarySrv.findCustomCompanyListByUnit(
          $scope.task,
          customCompanyList
        );
        if (customCompanyListByUnit) {
          $scope.task.unit.list = angular.copy(customCompanyListByUnit);
        }
        $scope.task.units = $scope.selectedTask
          ? $scope.selectedTask.units
          : $scope.task.units;
        $scope.task.unitPrice = CostsSummarySrv.searchPrice($scope.task);
      };

      const calculateContributorTradosPrice = (contributor = {}) => {
        const { trados = [] } = contributor;
        let price = 0;
        trados.forEach(({ quantity = 0, unitPrice = 0 }) => {
          price += quantity * unitPrice;
        });
        return price || 0;
      };

      $scope.setContributorCost = (contributor = {}) => {
        let basePrice = 0;
        if ($scope.isTradosRequiredForContributor(contributor)) {
          basePrice = calculateContributorTradosPrice(contributor);
        } else {
          const { quantity, unitPrice } = contributor;
          basePrice = unitPrice * quantity || 0;
        }
        const networkPrice =
          contributor.networkUnitPrice * contributor.networkUnitQuantity || 0;
        const networkSecondPrice =
          contributor.networkSecondUnitPrice *
            contributor.networkSecondUnitQuantity || 0;
        contributor.price = basePrice + networkPrice + networkSecondPrice;
        if ($scope.isContributor) {
          $scope.task.contributorCost = contributor.price;
        }
      };

      $scope.onContributorUnitChange = (contributor, options) => {
        $timeout(() => {
          const { isNetworkUnit, isNetworkSecondUnit } = options || {};
          const {
            networkUnit = {},
            networkSecondUnit = {},
            servicesList = [],
            unit = {}
          } = contributor || {};
          const { _id, trados } = task || $scope.task || {};
          let byUnit;

          if (isNetworkUnit) byUnit = networkUnit;
          else if (isNetworkSecondUnit) byUnit = networkSecondUnit;
          else byUnit = unit;

          const contributorService = CostsSummarySrv.findMatchingService(
            servicesList,
            _id
          );
          if (!contributorService) return;

          const contributorCombo = CostsSummarySrv.findMatchingComboByUnit(
            contributorService.combos,
            byUnit
          );
          if (!contributorCombo) return;

          let unitPriceName;

          if (isNetworkUnit) unitPriceName = 'networkUnitPrice';
          else if (isNetworkSecondUnit)
            unitPriceName = 'networkSecondUnitPrice';
          else unitPriceName = 'unitPrice';

          contributor[unitPriceName] = contributorCombo.price || 0;

          const totalQuantities = CostsSummarySrv.totalQuantities(trados) || 1;
          const basePrice =
            contributor.unitPrice * contributor.quantity * totalQuantities || 0;
          const networkPrice =
            contributor.networkUnitPrice *
              contributor.networkUnitQuantity *
              totalQuantities || 0;
          const networkSecondPrice =
            contributor.networkSecondUnitPrice *
              contributor.networkSecondUnitQuantity *
              totalQuantities || 0;

          contributor.price = basePrice + networkPrice + networkSecondPrice;

          if ($scope.isContributor) {
            task.contributorCost = contributor.price;
          }
        });
      };

      $scope.setTaskPrices = () => {
        const { unitPrice, quantity } = $scope.task;
        if ((unitPrice || unitPrice === 0) && quantity) {
          $scope.task.amount = unitPrice * quantity;
        } else delete $scope.task.amount;
      };
    }
  ]);
