angular
  .module('task')

  .factory('TasksExport', [
    '$http',
    '$timeout',
    '$filter',
    'Dialog',
    function($http, $timeout, $filter, Dialog) {
      const download = (_filters = {}) => {
        const { contributor, ...filters } = _filters;

        let exportUrl =
          '@@apiUrl' +
          '/contributor-tasks-export' +
          `?contributor=${contributor}`;

        if (filters && Object.keys(filters).length) {
          exportUrl += `&filter=${JSON.stringify(filters)}`;
        }

        const exportParams = { method: 'GET', url: exportUrl };

        $http(exportParams)
          .then(() => {
            $timeout(
              () =>
                new Dialog().info(
                  $filter('translate')('EXPORT_EMAIL_SUCCESS_1') +
                    '\n' +
                    $filter('translate')('EXPORT_EMAIL_SUCCESS_2')
                ),
              200
            );
          })
          .catch(error => {
            Sentry.captureException(error);
            $timeout(
              () =>
                new Dialog().error($filter('translate')('error.RECEIVE_ERR')),
              200
            );
          });
      };

      return { download };
    }
  ]);
